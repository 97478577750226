import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Common/Layout";
import About from "../components/Website/About";
import Disciplines from "../components/Website/Disciplines";
import OurServices from "../components/Website/OurServices";
import WorkWithUs from "../components/Website/WorkWithUs";

const Solutions = ({ data }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  return (
    <Layout
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      selectedLanguage="en"
      location="services"
      title="Brocode Studio || Services page"
      description="This a presentation of some of the services brocode can help you with"
    >
      <About selectedLanguage="en" />
      <Disciplines selectedLanguage="en" />
      <OurServices setModalVisible={setModalVisible} selectedLanguage="en" />
      <WorkWithUs setModalVisible={setModalVisible} selectedLanguage="en" />
    </Layout>
  );
};

export default Solutions;
